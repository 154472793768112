export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AggregationOptionInterface: ['AggregationOption'],
    AttributeSelectedOptionInterface: ['AttributeSelectedOption'],
    AttributeValueInterface: ['AttributeSelectedOptions', 'AttributeValue'],
    CartAddressInterface: ['BillingCartAddress', 'ShippingCartAddress'],
    CartItemInterface: [
      'BundleCartItem',
      'ConfigurableCartItem',
      'DownloadableCartItem',
      'SimpleCartItem',
      'VirtualCartItem',
    ],
    CategoryInterface: ['CategoryTree'],
    CraftAccountSectionEntryUnion: ['CraftAccountEntry'],
    CraftAddressInterface: ['CraftAddress'],
    CraftAssetInterface: ['CraftMediaAsset'],
    CraftBlockTitleWithStylingMatrixField: [
      'CraftBlockTitleWithStylingBlockEntry',
    ],
    CraftBlogOverviewSectionEntryUnion: ['CraftBlogOverviewEntry'],
    CraftBlogPostCardsMatrixField: ['CraftBlogPostCardsBlockEntry'],
    CraftBlogSectionEntryUnion: ['CraftBlogPostEntry'],
    CraftCategoryBannersMatrixField: ['CraftCategoryBannersBlockEntry'],
    CraftCategoryFilterExplanationsSectionEntryUnion: [
      'CraftFilterExplanationEntry',
    ],
    CraftCategoryPageContentSectionEntryUnion: [
      'CraftCategoryEntry',
      'CraftCategoryLandingEntry',
    ],
    CraftCategoryProductPageBlocksMatrixField: [
      'CraftCategoryImageWithProductOverviewEntry',
      'CraftHtml2Entry',
    ],
    CraftCategorySectionEntryUnion: ['CraftCategoryCategoryEntry'],
    CraftCollapsibleRichTextsMatrixField: ['CraftCollapsibleRichTextEntry'],
    CraftCollapsibleTextsMatrixField: ['CraftCollapsibleTextsBlockEntry'],
    CraftColumnsMatrixField: ['CraftColumnsBlockEntry'],
    CraftContactSectionEntryUnion: ['CraftContactEntry'],
    CraftCustomerServiceOverviewSectionEntryUnion: [
      'CraftCustomerServiceOverviewEntry',
    ],
    CraftCustomerServiceSectionEntryUnion: ['CraftFaqPageEntry'],
    CraftElementInterface: [
      'CraftAccordionInformationEntry',
      'CraftAccountEntry',
      'CraftAddress',
      'CraftBlockSeparationEntry',
      'CraftBlockTitleWithStylingBlockEntry',
      'CraftBlogOverviewEntry',
      'CraftBlogPostCardsBlockEntry',
      'CraftBlogPostEntry',
      'CraftCategoryBannersBlockEntry',
      'CraftCategoryCategoryEntry',
      'CraftCategoryEntry',
      'CraftCategoryImageWithProductOverviewEntry',
      'CraftCategoryLandingEntry',
      'CraftCheckoutFooterGlobalSet',
      'CraftCheckoutGlobalSet',
      'CraftCollapsibleRichTextEntry',
      'CraftCollapsibleTextsBlockEntry',
      'CraftColumnsBlockEntry',
      'CraftCompareSpecificationsEntry',
      'CraftContactEntry',
      'CraftContactGlobalGlobalSet',
      'CraftContentBlocksEntry',
      'CraftCustomerServiceOverviewEntry',
      'CraftDefaultEntry',
      'CraftDisclaimersGlobalGlobalSet',
      'CraftEcommerceGlobalSet',
      'CraftElement',
      'CraftFaqItemEntry',
      'CraftFaqItemsEntry',
      'CraftFaqPageEntry',
      'CraftFeaturedCategoriesEntry',
      'CraftFilterExplanationEntry',
      'CraftFilterExplanationItemsBlockEntry',
      'CraftFilterExplanationListEntry',
      'CraftFooterGlobalSet',
      'CraftForgotPasswordEntry',
      'CraftGenericBlockLinkEntry',
      'CraftHeroEntry',
      'CraftHeroLinksBlockEntry',
      'CraftHighlightBannerLink2Entry',
      'CraftHighlightBannerLinkEntry',
      'CraftHomepageEntry',
      'CraftHtml2Entry',
      'CraftHtmlEntry',
      'CraftIconLinksBlockEntry',
      'CraftImageContentBanner2Entry',
      'CraftImageContentBannerEntry',
      'CraftImageContentItemEntry',
      'CraftImageEntry',
      'CraftImageWithProductOverview2Entry',
      'CraftImageWithProductOverviewEntry',
      'CraftInformationBalanceBikesEntry',
      'CraftInformationGoCartsEntry',
      'CraftInformationItemFieldsEntry',
      'CraftInformationItemSubjectEntry',
      'CraftInformationItemsBlockEntry',
      'CraftInformationOverviewEntry',
      'CraftInformationPlaygroundEquipmentEntry',
      'CraftInformationRideOnCarsEntry',
      'CraftInformationScootersEntry',
      'CraftInformationSubjectBalanceBikesEntry',
      'CraftInformationSubjectGoCartsEntry',
      'CraftInformationSubjectPlaygroundEquipmentEntry',
      'CraftInformationSubjectRideOnCarsEntry',
      'CraftInformationSubjectScootersEntry',
      'CraftInformationSubjectTrampolinesEntry',
      'CraftInformationTrampolinesEntry',
      'CraftInstagramImagesGlobalGlobalSet',
      'CraftItems2BlockEntry',
      'CraftItems3BlockEntry',
      'CraftItemsBlockEntry',
      'CraftLinkGroupEntry',
      'CraftLinkGroupLinkEntry',
      'CraftLinkGroups2BlockEntry',
      'CraftLinks2BlockEntry',
      'CraftLinks3BlockEntry',
      'CraftLinksBlockEntry',
      'CraftLoginEntry',
      'CraftMediaAsset',
      'CraftMenuColumnEntry',
      'CraftMenuGlobalSet',
      'CraftMenuItem2Entry',
      'CraftMenuItemEntry',
      'CraftNewsEntry',
      'CraftProductEntry',
      'CraftProductPageFaqItemEntry',
      'CraftProductRecommendationsEntry',
      'CraftProductRegistrationEntry',
      'CraftProductTypeEntry',
      'CraftQuickFiltersBlockEntry',
      'CraftRequestLicensePlateEntry',
      'CraftReviewEntry',
      'CraftSearchEntry',
      'CraftSeoGlobalGlobalSet',
      'CraftSimpleContentEntry',
      'CraftSiteGlobalContactBlockEntry',
      'CraftSparepartsEntry',
      'CraftSpecificationEntry',
      'CraftStoreEntry',
      'CraftStoreLocatorEntry',
      'CraftTextColumnsBlockEntry',
      'CraftTextColumnsEntry',
      'CraftTitleWithPartialStyling2BlockEntry',
      'CraftTitleWithPartialStylingBlockEntry',
      'CraftUser',
      'CraftUspEntry',
      'CraftUspsBlockEntry',
      'CraftUspsEntry',
      'CraftUspsGlobalGlobalSet',
      'CraftUspsProductPageGlobalSet',
      'CraftWizardSliderEntry',
      'CraftWizardStepsBlockEntry',
      'CraftYoutubeVideoEntry',
    ],
    CraftEntryInterface: [
      'CraftAccordionInformationEntry',
      'CraftAccountEntry',
      'CraftBlockSeparationEntry',
      'CraftBlockTitleWithStylingBlockEntry',
      'CraftBlogOverviewEntry',
      'CraftBlogPostCardsBlockEntry',
      'CraftBlogPostEntry',
      'CraftCategoryBannersBlockEntry',
      'CraftCategoryCategoryEntry',
      'CraftCategoryEntry',
      'CraftCategoryImageWithProductOverviewEntry',
      'CraftCategoryLandingEntry',
      'CraftCollapsibleRichTextEntry',
      'CraftCollapsibleTextsBlockEntry',
      'CraftColumnsBlockEntry',
      'CraftCompareSpecificationsEntry',
      'CraftContactEntry',
      'CraftContentBlocksEntry',
      'CraftCustomerServiceOverviewEntry',
      'CraftDefaultEntry',
      'CraftFaqItemEntry',
      'CraftFaqItemsEntry',
      'CraftFaqPageEntry',
      'CraftFeaturedCategoriesEntry',
      'CraftFilterExplanationEntry',
      'CraftFilterExplanationItemsBlockEntry',
      'CraftFilterExplanationListEntry',
      'CraftForgotPasswordEntry',
      'CraftGenericBlockLinkEntry',
      'CraftHeroEntry',
      'CraftHeroLinksBlockEntry',
      'CraftHighlightBannerLink2Entry',
      'CraftHighlightBannerLinkEntry',
      'CraftHomepageEntry',
      'CraftHtml2Entry',
      'CraftHtmlEntry',
      'CraftIconLinksBlockEntry',
      'CraftImageContentBanner2Entry',
      'CraftImageContentBannerEntry',
      'CraftImageContentItemEntry',
      'CraftImageEntry',
      'CraftImageWithProductOverview2Entry',
      'CraftImageWithProductOverviewEntry',
      'CraftInformationBalanceBikesEntry',
      'CraftInformationGoCartsEntry',
      'CraftInformationItemFieldsEntry',
      'CraftInformationItemSubjectEntry',
      'CraftInformationItemsBlockEntry',
      'CraftInformationOverviewEntry',
      'CraftInformationPlaygroundEquipmentEntry',
      'CraftInformationRideOnCarsEntry',
      'CraftInformationScootersEntry',
      'CraftInformationSubjectBalanceBikesEntry',
      'CraftInformationSubjectGoCartsEntry',
      'CraftInformationSubjectPlaygroundEquipmentEntry',
      'CraftInformationSubjectRideOnCarsEntry',
      'CraftInformationSubjectScootersEntry',
      'CraftInformationSubjectTrampolinesEntry',
      'CraftInformationTrampolinesEntry',
      'CraftItems2BlockEntry',
      'CraftItems3BlockEntry',
      'CraftItemsBlockEntry',
      'CraftLinkGroupEntry',
      'CraftLinkGroupLinkEntry',
      'CraftLinkGroups2BlockEntry',
      'CraftLinks2BlockEntry',
      'CraftLinks3BlockEntry',
      'CraftLinksBlockEntry',
      'CraftLoginEntry',
      'CraftMenuColumnEntry',
      'CraftMenuItem2Entry',
      'CraftMenuItemEntry',
      'CraftNewsEntry',
      'CraftProductEntry',
      'CraftProductPageFaqItemEntry',
      'CraftProductRecommendationsEntry',
      'CraftProductRegistrationEntry',
      'CraftProductTypeEntry',
      'CraftQuickFiltersBlockEntry',
      'CraftRequestLicensePlateEntry',
      'CraftReviewEntry',
      'CraftSearchEntry',
      'CraftSimpleContentEntry',
      'CraftSiteGlobalContactBlockEntry',
      'CraftSparepartsEntry',
      'CraftSpecificationEntry',
      'CraftStoreEntry',
      'CraftStoreLocatorEntry',
      'CraftTextColumnsBlockEntry',
      'CraftTextColumnsEntry',
      'CraftTitleWithPartialStyling2BlockEntry',
      'CraftTitleWithPartialStylingBlockEntry',
      'CraftUspEntry',
      'CraftUspsBlockEntry',
      'CraftUspsEntry',
      'CraftWizardSliderEntry',
      'CraftWizardStepsBlockEntry',
      'CraftYoutubeVideoEntry',
    ],
    CraftFaqItemsMatrixField: ['CraftFaqItemEntry'],
    CraftFilterExplanationItemsMatrixField: [
      'CraftFilterExplanationItemsBlockEntry',
    ],
    CraftFilterExplanationListsSectionEntryUnion: [
      'CraftFilterExplanationListEntry',
    ],
    CraftFooterLinksMatrixField: ['CraftLinksBlockEntry'],
    CraftForgotPasswordSectionEntryUnion: ['CraftForgotPasswordEntry'],
    CraftGenericBlockLinksSectionEntryUnion: ['CraftGenericBlockLinkEntry'],
    CraftGlobalSetInterface: [
      'CraftCheckoutFooterGlobalSet',
      'CraftCheckoutGlobalSet',
      'CraftContactGlobalGlobalSet',
      'CraftDisclaimersGlobalGlobalSet',
      'CraftEcommerceGlobalSet',
      'CraftFooterGlobalSet',
      'CraftInstagramImagesGlobalGlobalSet',
      'CraftMenuGlobalSet',
      'CraftSeoGlobalGlobalSet',
      'CraftUspsGlobalGlobalSet',
      'CraftUspsProductPageGlobalSet',
    ],
    CraftHeroLinksMatrixField: ['CraftHeroLinksBlockEntry'],
    CraftHomepageSectionEntryUnion: ['CraftHomepageEntry'],
    CraftIconLinksMatrixField: ['CraftIconLinksBlockEntry'],
    CraftImageWithProductOverviewsMatrixField: [
      'CraftImageWithProductOverview2Entry',
    ],
    CraftInformationBalanceBikesSectionEntryUnion: [
      'CraftInformationBalanceBikesEntry',
    ],
    CraftInformationGoCartsSectionEntryUnion: ['CraftInformationGoCartsEntry'],
    CraftInformationItemShortMatrixField: ['CraftInformationItemFieldsEntry'],
    CraftInformationItemSubjectsSectionEntryUnion: [
      'CraftInformationItemSubjectEntry',
    ],
    CraftInformationItemsMatrixField: ['CraftInformationItemsBlockEntry'],
    CraftInformationOverviewSectionEntryUnion: [
      'CraftInformationOverviewEntry',
    ],
    CraftInformationPlaygroundEquipmentSectionEntryUnion: [
      'CraftInformationPlaygroundEquipmentEntry',
    ],
    CraftInformationRideOnCarsSectionEntryUnion: [
      'CraftInformationRideOnCarsEntry',
    ],
    CraftInformationScootersSectionEntryUnion: [
      'CraftInformationScootersEntry',
    ],
    CraftInformationSubjectBalanceBikesSectionEntryUnion: [
      'CraftInformationSubjectBalanceBikesEntry',
    ],
    CraftInformationSubjectGoCartsSectionEntryUnion: [
      'CraftInformationSubjectGoCartsEntry',
    ],
    CraftInformationSubjectPlaygroundEquipmentSectionEntryUnion: [
      'CraftInformationSubjectPlaygroundEquipmentEntry',
    ],
    CraftInformationSubjectRideOnCarsSectionEntryUnion: [
      'CraftInformationSubjectRideOnCarsEntry',
    ],
    CraftInformationSubjectScootersSectionEntryUnion: [
      'CraftInformationSubjectScootersEntry',
    ],
    CraftInformationSubjectTrampolinesSectionEntryUnion: [
      'CraftInformationSubjectTrampolinesEntry',
    ],
    CraftInformationTrampolinesSectionEntryUnion: [
      'CraftInformationTrampolinesEntry',
    ],
    CraftItems2MatrixField: ['CraftItems2BlockEntry'],
    CraftItemsMatrixField: ['CraftItemsBlockEntry'],
    CraftLinkGroupLinksMatrixField: ['CraftLinkGroupLinkEntry'],
    CraftLinkGroupsMatrixField: ['CraftLinkGroups2BlockEntry'],
    CraftLinksMatrixField: ['CraftLinks2BlockEntry'],
    CraftLoginSectionEntryUnion: ['CraftLoginEntry'],
    CraftMenuItemsMatrixField: ['CraftMenuItem2Entry'],
    CraftMenuItemsSectionEntryUnion: ['CraftMenuItemEntry'],
    CraftMenuLinksMatrixField: ['CraftLinksBlockEntry'],
    CraftMenuListsMatrixField: ['CraftMenuColumnEntry'],
    CraftPageBlocksMatrixField: [
      'CraftAccordionInformationEntry',
      'CraftCompareSpecificationsEntry',
      'CraftFeaturedCategoriesEntry',
      'CraftHeroEntry',
      'CraftHighlightBannerLink2Entry',
      'CraftHtmlEntry',
      'CraftImageContentBanner2Entry',
      'CraftImageEntry',
      'CraftImageWithProductOverviewEntry',
      'CraftNewsEntry',
      'CraftProductRecommendationsEntry',
      'CraftSimpleContentEntry',
      'CraftTextColumnsEntry',
      'CraftWizardSliderEntry',
      'CraftYoutubeVideoEntry',
    ],
    CraftPageSectionEntryUnion: ['CraftDefaultEntry'],
    CraftProductPageBlocksMatrixField: [
      'CraftBlockSeparationEntry',
      'CraftFaqItemsEntry',
      'CraftHighlightBannerLinkEntry',
      'CraftImageContentBannerEntry',
      'CraftUspsEntry',
    ],
    CraftProductPageContentSectionEntryUnion: ['CraftContentBlocksEntry'],
    CraftProductPageFaqItemsSectionEntryUnion: ['CraftProductPageFaqItemEntry'],
    CraftProductPageGenericItemsSectionEntryUnion: [
      'CraftImageContentItemEntry',
      'CraftUspEntry',
    ],
    CraftProductRegistrationSectionEntryUnion: [
      'CraftProductRegistrationEntry',
    ],
    CraftProductTypesSectionEntryUnion: ['CraftProductTypeEntry'],
    CraftProductsSectionEntryUnion: ['CraftProductEntry'],
    CraftQuickFiltersMatrixField: ['CraftQuickFiltersBlockEntry'],
    CraftRequestLicensePlateSectionEntryUnion: [
      'CraftRequestLicensePlateEntry',
    ],
    CraftReviewSectionEntryUnion: ['CraftReviewEntry'],
    CraftSearchSectionEntryUnion: ['CraftSearchEntry'],
    CraftSiteGlobalContactMatrixField: ['CraftSiteGlobalContactBlockEntry'],
    CraftSparepartsSectionEntryUnion: ['CraftSparepartsEntry'],
    CraftSpecificationsMatrixField: ['CraftSpecificationEntry'],
    CraftStoreLocatorSectionEntryUnion: ['CraftStoreLocatorEntry'],
    CraftStoresSectionEntryUnion: ['CraftStoreEntry'],
    CraftTextColumnsMatrixField: ['CraftTextColumnsBlockEntry'],
    CraftTitleWithPartialStyling2MatrixField: [
      'CraftTitleWithPartialStylingBlockEntry',
    ],
    CraftTitleWithPartialStylingMatrixField: [
      'CraftTitleWithPartialStyling2BlockEntry',
    ],
    CraftUserInterface: ['CraftUser'],
    CraftUspsMatrixField: ['CraftUspsBlockEntry'],
    CraftWizardStepsMatrixField: ['CraftWizardStepsBlockEntry'],
    Craftitems__MatrixField: ['CraftItems3BlockEntry'],
    CraftlinkGroups__MatrixField: ['CraftLinkGroupEntry'],
    Craftlinks__MatrixField: ['CraftLinks3BlockEntry'],
    CreditMemoItemInterface: [
      'BundleCreditMemoItem',
      'CreditMemoItem',
      'DownloadableCreditMemoItem',
    ],
    CustomAttributeMetadataInterface: [
      'AttributeMetadata',
      'CatalogAttributeMetadata',
      'CustomerAttributeMetadata',
    ],
    CustomAttributeOptionInterface: ['AttributeOptionMetadata'],
    CustomizableOptionInterface: [
      'CustomizableAreaOption',
      'CustomizableCheckboxOption',
      'CustomizableDateOption',
      'CustomizableDropDownOption',
      'CustomizableFieldOption',
      'CustomizableFileOption',
      'CustomizableMultipleOption',
      'CustomizableRadioOption',
    ],
    CustomizableProductInterface: [
      'BundleProduct',
      'ConfigurableProduct',
      'DownloadableProduct',
      'SimpleProduct',
      'VirtualProduct',
    ],
    ErrorInterface: ['InternalError', 'NoSuchEntityUidError'],
    InvoiceItemInterface: [
      'BundleInvoiceItem',
      'DownloadableInvoiceItem',
      'InvoiceItem',
    ],
    LayerFilterItemInterface: ['LayerFilterItem', 'SwatchLayerFilterItem'],
    MediaGalleryInterface: ['ProductImage', 'ProductVideo'],
    OrderItemInterface: [
      'BundleOrderItem',
      'DownloadableOrderItem',
      'OrderItem',
    ],
    PaymentConfigItem: [
      'ApplePayConfig',
      'GooglePayConfig',
      'HostedFieldsConfig',
      'PaymentCommonConfig',
      'SmartButtonsConfig',
    ],
    PhysicalProductInterface: [
      'BundleProduct',
      'ConfigurableProduct',
      'GroupedProduct',
      'SimpleProduct',
    ],
    ProductInterface: [
      'BundleProduct',
      'ConfigurableProduct',
      'DownloadableProduct',
      'GroupedProduct',
      'SimpleProduct',
      'VirtualProduct',
    ],
    ProductLinksInterface: ['ProductLinks'],
    RoutableInterface: [
      'BundleProduct',
      'CategoryTree',
      'CmsPage',
      'ConfigurableProduct',
      'DownloadableProduct',
      'GroupedProduct',
      'LandingPage',
      'SimpleProduct',
      'VirtualProduct',
    ],
    ShipmentItemInterface: ['BundleShipmentItem', 'ShipmentItem'],
    SwatchDataInterface: [
      'ColorSwatchData',
      'ImageSwatchData',
      'TextSwatchData',
    ],
    SwatchLayerFilterItemInterface: ['SwatchLayerFilterItem'],
    WishlistItemInterface: [
      'BundleWishlistItem',
      'ConfigurableWishlistItem',
      'DownloadableWishlistItem',
      'GroupedProductWishlistItem',
      'SimpleWishlistItem',
      'VirtualWishlistItem',
    ],
  },
}
export default result
